import ReactGA from "react-ga4";
import { AppProvider } from "@shopify/polaris";
import translations from "@shopify/polaris/locales/en.json";
import { LaunchDarklyOptions } from "@smartrr/shared/LaunchDarkly";
import { RouterProvider } from "@tanstack/react-router";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import { initStore } from "./app/_state/store";
import { router } from "./router";
import { loadZendeskChat } from "./scripts/loadZendeskChat";

import "rsuite/dist/rsuite.min.css";
import { loadWebVitals } from "./utils/loadWebVitals";
import config from "@vendor-app/config";
import { waitForEle } from "@smartrr/shared/utils/simulateActions";
import { noawait } from "@smartrr/shared/utils/noawait";

initializeGA();
loadWebVitals();

// waiting until navigation is loaded before initializing third-party services
noawait(() =>
  waitForEle(".Polaris-TopBar__Logo").then(() => {
    loadZendeskChat();
  })
);

function App() {
  return (
    <Provider store={initStore()}>
      <AppProvider i18n={translations}>
        <RouterProvider router={router} />
      </AppProvider>
    </Provider>
  );
}

const AppWithLD = withLDProvider({
  clientSideID: config.launchDarkly.clientSideId,
  ...LaunchDarklyOptions,
})(App);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <AppWithLD />
  </React.StrictMode>
);

function initializeGA() {
  const measurementId = config.googleAnalytics.measurementId;
  if (!measurementId || measurementId === "G-") {
    return;
  }
  ReactGA.initialize(measurementId);
}
