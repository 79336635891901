/* eslint-disable no-console */

export const captureException = (message: string, cause?: any): void => {
  const error = new Error(message);
  (error as any).cause = cause;
  if (cause) {
    console.error(message, cause);
  } else {
    console.error(message);
  }
};
