import { ISODateString } from "@smartrr/shared/entities/ISODateString";
import { IPurchasable, IPurchasablesReducerState } from "@smartrr/shared/entities/Purchasable";
import { sortByDate } from "@smartrr/shared/utils/sortBy";
import { updateMatchInArray } from "@smartrr/shared/utils/updateMatchInArray";
import {
  useVariantShopifyIDToPurchasableMap,
  useVariantToPurchasableMap,
} from "@smartrr/shared/utils/useVariantToPurchasableMap";
import { flatten } from "lodash";
import { useMemo } from "react";

import { SmartrrVendorSubReducer } from "@vendor-app/app/_state/typedVendorRedux";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

const initialState: IPurchasablesReducerState = {
  isLoading: true,
  isLoadingAdditionaly: true,
  isStreaming: false,
  purchasables: [],
  pageNumber: 0,
  pageSize: 0,
  totalCount: 0,
  totalPages: 0,
};

export const purchasablesReducer: SmartrrVendorSubReducer<IPurchasablesReducerState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "SELECT_ACTIVE_ORGANIZATION": {
      return initialState;
    }

    case "LOADING_PURCHASABLES": {
      return {
        ...state,
        purchasables: [],
        isLoading: true,
      };
    }

    case "ERROR_LOADING_PURCHASABLES": {
      return {
        ...state,
        isLoading: false,
      };
    }

    case "LOADED_PURCHASABLES": {
      return {
        ...state,
        isLoading: false,
        totalPages: action.payload.purchasables.totalPages,
        totalCount: action.payload.purchasables.totalCount,
        pageNumber: action.payload.purchasables.pageNumber,
        pageSize: action.payload.purchasables.pageSize,
        purchasables: action.payload.purchasables.data,
      };
    }

    case "LOADED_ADDITIONAL_PURCHASABLES": {
      return {
        ...state,
        isLoadingAdditionaly: false,
        totalPages: action.payload.purchasables.totalPages,
        totalCount: action.payload.purchasables.totalCount,
        pageNumber: action.payload.purchasables.pageNumber,
        pageSize: action.payload.purchasables.pageSize,
        purchasables: action.payload.purchasables.data,
      };
    }

    case "STREAMING_SHOPIFY_PRODUCT_DATA": {
      return {
        ...state,
        isStreaming: true,
      };
    }

    case "UPDATED_PURCHASABLE":
    case "STREAMING_SHOPIFY_PRODUCT_DATA_ITEM": {
      const purchasableFromPayload = action.payload.purchasable;
      const fromPayloadVariants = purchasableFromPayload.vnts;
      const upsertedPurchasable = {
        // cloned and using already-existing
        ...(state.purchasables.find(({ id }) => id === purchasableFromPayload.id) || purchasableFromPayload),
      };
      const existingVnts = upsertedPurchasable.vnts || [];

      upsertedPurchasable.vnts = (fromPayloadVariants?.length ? fromPayloadVariants : existingVnts).reduce(
        (variantsState, variant) => updateMatchInArray(variantsState, variant, ({ id }) => id, "existingValue"),
        existingVnts
      );

      return {
        ...state,
        purchasables: updateMatchInArray(
          state.purchasables,
          sortPurchasableVariantsByDate(upsertedPurchasable),
          purchasable => purchasable.id
        ),
      };
    }

    case "ERROR_STREAMING_SHOPIFY_PRODUCT_DATA": {
      return {
        ...state,
        isStreaming: false,
      };
    }

    case "STREAMING_SHOPIFY_PRODUCT_DATA_COMPLETE": {
      return {
        ...state,
        isStreaming: false,
      };
    }

    default: {
      return state;
    }
  }
};

function sortPurchasableVariantsByDate(purchasable: IPurchasable) {
  return {
    ...purchasable,
    vnts: sortByDate([...(purchasable.vnts || [])], variant => ISODateString.fromString(variant.createdDate)),
  };
}

export function useVendorPortalVariantToPurchasableMap() {
  return useVariantToPurchasableMap(useSmartrrVendorSelector(state => state.purchasables.purchasables));
}

export function useVendorPortalVariantShopifyIdToPurchasableMap() {
  return useVariantShopifyIDToPurchasableMap(useSmartrrVendorSelector(state => state.purchasables.purchasables));
}

export const usePurchasableVariants = () => {
  const { purchasables } = useSmartrrVendorSelector(state => state.purchasables);

  return useMemo(() => flatten(purchasables.map(p => p.vnts || [])), [purchasables]);
};
